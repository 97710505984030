<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("verify_code.title") }}</h1>
      </div>
    </div>
    <hr />
    <br />
    <div class="section-action">
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <label class="label-input">{{
            $t("verify_code.search_title")
          }}</label>
          <v-text-field
            class="btn btn-color btn-border btn-border-color"
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('Search.search')"
            v-model="employee_name"
            @keypress.enter="fetchEmployeeVerify"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listEmployeeVerify.length > 0">
          <thead>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">
                {{ $t("verify_code.table.emp_number") }}
              </th>
              <th class="text-left">{{ $t("verify_code.table.emp_name") }}</th>
              <th class="text-left">{{ $t("verify_code.table.email") }}</th>
              <th class="text-left">
                {{ $t("verify_code.table.emp_verify") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listEmployeeVerify" :key="idx">
              <td>{{ idx + 1 }}</td>
              <td>{{ item.emp_number }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.verification_code }}</td>
            </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else />

        <Pagination
          class="mt-5 mb-5"
          v-if="pagination.total_pages > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchEmployeeVerify"
        >
        </Pagination>
        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Paginate/Pagination";
import Loading from "@/components/Loading";
import defaultTableNoResult from "@/components/defaultTableNoResult";

export default {
  components: {
    Pagination,
    Loading,
    defaultTableNoResult,
  },
  data() {
    return {
      isLoading: true,
      listEmployeeVerify: [],
      employee_name: "",
      pagination: {},
      offset: 10,
      isLoading: true,
    };
  },
  methods: {
    fetchEmployeeVerify() {
      this.isLoading = true;
      this.$axios
        .get(`company/employee/verify-code/list`, {
          params: {
            filter: this.employee_name,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmployeeVerify = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployeeVerify();
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 20px;
}
</style>
